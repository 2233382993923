* {
  font-family: "Playpen Sans", cursive;
}

@import url('https://fonts.googleapis.com/css2?family=Freeman&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playpen+Sans:wght@100..800&display=swap');

.logoPic:hover {
  cursor: pointer;
}

.networks img:hover {
  filter: grayscale(0);
}

.notification.show {
  opacity: 1;
}

.btnfos-0-3:disabled {
  cursor: no-drop;
  opacity: 0.5;
}

@keyframes zoom {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes shake {

  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

@keyframes dots {

  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  40% {
    color: rgb(151, 151, 151);
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  60% {
    text-shadow:
      .25em 0 0 rgb(124, 124, 124),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  80%,
  100% {
    text-shadow:
      .25em 0 0 rgb(121, 121, 121),
      .5em 0 0 rgb(121, 121, 121);
  }
}

.btnfos-0-2:hover {
  border-color: black;
}

.left {
  text-align: left;
}

@media screen and (max-width: 499px) {
  .networks {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    margin-top: 1%;
    margin-bottom: 50px;
    background-color: #292929;
    width: 95%;
    padding: 15px;
    border-radius: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    margin-left: auto;
    margin-right: auto;
  }

  .networks img {
    width: 100px;
    padding: 5px;
    cursor: pointer;
    filter: grayscale(1);
    transition: 0.2s ease-in-out;
  }

  .container {
    display: flex;
    background-color: #1e1e1e;
    width: fit-content;
    justify-content: center;
    padding: 25px;
    margin-right: auto;
    box-shadow: -5px 5px rgba(54, 54, 54, 0.267);
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    width: 100%;
    font-size: 14px;

  }

  .container2 {
    display: flex;

  }

  code {
    color: rgb(86, 192, 15);
    text-align: left;
  }

  .address-section p {
    color: #fff;
    margin: 0;
    text-align: left;
  }

  .copy-button {
    margin-left: 20px;
    background-color: white;
    color: black;
    border: 1px solid rgb(231, 255, 199);
    border-radius: 6px;
    font-weight: 500;
    transition: 0.2s ease-in-out;
    font-size: 14px;
  }

  .copy-button:hover {
    background-color: #444;
    /* Darker gray on hover */
    color: white;
  }

  .copy-button:active {
    background-color: #81b457;
    /* Darker gray on click */
    transform: translateY(2px);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
  }

  .copy-button:focus {
    outline: none;
  }

  .notification {
    position: fixed;
    bottom: 20px;
    left: 40px;
    background-color: #42b932;
    color: #ffffff;
    padding: 10px 20px;
    border-radius: 5px;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    z-index: 9999;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .wallet2Mobile {
    display: none;
  }

  .prices p {
    color: white;
    background-color: #292929;
    border-radius: 7px;
    border: 1px solid rgba(255, 255, 255, 0.178);
    padding: 6px;
    font-size: 14px;
    margin-right: 10px;
  }

  .prices {
    width: fit-content;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    flex-flow: row wrap;
    justify-content: center;
  }


  .intro2Main {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: #3b3b3b;
    width: 90%;
    padding: 20px;
    margin-bottom: 70px;
    margin-top: 70px;
    border-radius: 20px;
  }

  .wallet3Mobile {
    display: none;
  }

  .intro {
    color: rgb(0, 0, 0);
    font-weight: 500;
    font-size: 25px;
  }

  .icons {
    display: flex;
    margin-bottom: auto;
    margin-top: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .nftPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 5%;
  }

  .mintingAmount {
    font-size: 20px;
    padding: 10px;
  }

  .socialIcon img {
    width: 35px;
    height: 35px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    font-size: 16px;
    color: #ffffff;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .intro2 {
    color: #3a3a3a;
    text-align: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    font-size: 15px;
  }

  .pricesM div {
    color: #3a3a3a;
    margin-bottom: 5px;
  }

  .mintDiv {
    background-color: #0000004f;
    width: 100%;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 5px solid black;
    padding: 20px;
    box-shadow: 0 0 20px #99999965;
    border-radius: 20px;
    border: 2px solid black;
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
  }

  .errorMessage {
    font-size: 16px;
    color: #f74741;
    text-align: center;
  }

  .loadingText {
    font-size: 16px;
    text-align: center;
    color: rgb(255, 255, 255);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  .errorMessage2 {
    font-size: 18px;
    color: #f74741;
    text-align: center;
  }

  .loadingText2 {
    font-size: 20px;
    text-align: center;
    color: rgb(255, 255, 255);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText2:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }



  .right {
    flex-flow: row nowrap;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    width: max-content;
  }



  .socialIcon {
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
    padding-right: 10px;
  }

  .socialIcon:hover {
    transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: "Playpen Sans", cursive;
    padding-top: 5px;
    margin-left: 10%;
  }

  .connect2 {
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: "Playpen Sans", cursive;
    color: white;
    padding-top: 15px;
    font-size: 16px;
  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: "Playpen Sans", cursive;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;

  }

  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    height: max-content;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .nftminter2 {
    font-size: 55px;
    color: white;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    justify-content: center;
  }

  .nftminter {
    background-color: white;
    justify-content: flex-start;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    color: white;
    border: white 2px solid;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 18px;
    margin-left: 20px;
    margin-right: 20px;
    width: 45px;
    height: 45px;
    border: none;
    cursor: pointer;
    justify-content: center;
    background-color: #1e1e1e;
    border: 2px solid #ffffff;
    border-radius: 6px;
  }

  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
  }

  .btnfos-0-3 img {
    width: 32px;
    height: 32px;
  }

  .btnfos-0-3 {
    position: relative;
    font-size: 15px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 8px;
    border: none;
    justify-content: center;
    text-align: center;
    width: 90%;
    color: white;
    background-color: #1e1e1e;
    border: #ffffff 2px solid;
    margin-top: 5%;
    border-radius: 8px;
  }

  .btnfos-0-3-Dis {
    cursor: no-drop;

    font-size: 20px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 8px;
    border: none;
    justify-content: center;
    text-align: center;
    width: 90%;
    margin-bottom: 5%;
    color: rgba(255, 255, 255, 0.116);
    background-color: #1e1e1e49;
    border: #3f3f3f5e 2px solid;
    margin-top: 5%;
    border-radius: 8px;
  }

  .btnfos-0-3:hover {
    color: rgb(153, 153, 153);
    ;
    cursor: pointer;
  }

  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
  }

  .loadingContainer {
    text-align: center;
  }

  .successfully {
    text-align: center;
    color: rgb(128, 240, 54);
    font-size: 18px;
  }

  .successfully2 {
    text-align: center;
    color: rgb(124, 199, 74);
    font-size: 25px;
  }

  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .wallet2 {
    background-color: #9e4c3c00;
    border: black 2px solid;
    color: white;
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: flex;
    font-size: 20px;
    padding-left: 25px;
    padding-right: 25px;
    transition: transform .2s;
    border-radius: 50px;
    font-weight: 500;
    margin-left: 10px;
  }

  .wallet2:hover {
    transform: scale(1.1);
  }

  .wallet3 {
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    padding-left: 80px;
    padding-right: 80px;
    align-items: center;
    display: flex;
    transition: transform .5s;
    font-weight: 500;
    color: black;
    border-radius: 100px;
    z-index: 100000000000;
    margin-top: 20px;
    padding-bottom: 12px;
    padding-top: 12px;
    border: none;
    background-color: white;
    border: 1px solid black;
    box-shadow: -5px 5px rgb(129, 129, 129);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
  }

  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }



  .in2 {
    width: 100%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    text-shadow: 4px 3px 0 #383d6e3a;
  }

  .logoPic {
    width: 90px;
  }

  .logo {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .light {
    background-color: white;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
  }

  .nftPic {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    border: 3px solid transparent;
    border-image: linear-gradient(90deg, #383838, #929292);
    border-image-slice: 3;



  }

}

@media screen and (min-width: 500px) and (max-width: 767px) {
  .networks {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    margin-top: 1%;
    margin-bottom: 50px;
    background-color: #292929;
    width: 95%;
    padding: 20px;
    border-radius: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    margin-left: auto;
    margin-right: auto;
  }

  .networks img {
    width: 120px;
    padding: 5px;
    cursor: pointer;
    filter: grayscale(1);
    transition: 0.2s ease-in-out;
  }

  .container {
    display: flex;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
  }

  .container2 {
    display: flex;
    background-color: #1e1e1e;
    width: fit-content;
    justify-content: center;
    padding: 15px;
    margin-right: auto;
    box-shadow: -5px 5px rgba(54, 54, 54, 0.267);
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
  }

  code {
    font-size: 18px;
    color: rgb(86, 192, 15);
  }

  .address-section p {
    color: #fff;
    margin: 0;
    font-size: 18px;
  }

  .copy-button {
    margin-left: 20px;
    background-color: white;
    color: black;
    border: 1px solid rgb(231, 255, 199);
    border-radius: 6px;
    font-weight: 500;
    transition: 0.2s ease-in-out;
  }

  .copy-button:hover {
    background-color: #444;
    /* Darker gray on hover */
    color: white;
  }

  .copy-button:active {
    background-color: #81b457;
    /* Darker gray on click */
    transform: translateY(2px);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
  }

  .copy-button:focus {
    outline: none;
  }

  .notification {
    position: fixed;
    bottom: 20px;
    left: 40px;
    background-color: #42b932;
    color: #ffffff;
    padding: 10px 20px;
    border-radius: 5px;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    z-index: 9999;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .wallet2Mobile {
    display: none;
  }

  .prices p {
    color: white;
    background-color: #292929;
    border-radius: 7px;
    border: 1px solid rgba(255, 255, 255, 0.178);
    padding: 6px;
    font-size: 12px;
    margin-right: 10px;
  }

  .prices {
    width: fit-content;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    flex-flow: row wrap;
    justify-content: center;
  }

  .intro2Main {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: #3b3b3b;
    width: 90%;
    padding: 20px;
    margin-bottom: 70px;
    margin-top: 70px;
    border-radius: 20px;
  }

  .wallet3Mobile {
    display: none;
  }

  .intro {
    color: rgb(0, 0, 0);
    font-weight: 500;
    font-size: 25px;
  }

  .icons {
    display: flex;
    margin-bottom: auto;
    margin-top: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .nftPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 5%;
  }

  .mintingAmount {
    font-size: 20px;
    padding: 10px;
  }

  .socialIcon img {
    width: 38px;
    height: 38px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    font-size: 18px;
    color: #ffffff;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .intro2 {
    color: #3a3a3a;
    text-align: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .pricesM div {
    color: #3a3a3a;
    margin-bottom: 5px;
  }

  .mintDiv {
    background-color: #0000004f;
    width: 100%;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 5px solid black;
    padding: 20px;
    box-shadow: 0 0 20px #99999965;
    border-radius: 20px;
    border: 2px solid black;
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
  }

  .errorMessage {
    font-size: 16px;
    color: #f74741;
    text-align: center;
  }

  .loadingText {
    font-size: 16px;
    text-align: center;
    color: rgb(255, 255, 255);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  .errorMessage2 {
    font-size: 18px;
    color: #f74741;
    text-align: center;
  }

  .loadingText2 {
    font-size: 20px;
    text-align: center;
    color: rgb(255, 255, 255);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText2:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }



  .right {
    flex-flow: row nowrap;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    width: max-content;
  }



  .socialIcon {
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
    padding-right: 10px;
  }

  .socialIcon:hover {
    transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: "Playpen Sans", cursive;
    padding-top: 5px;
    margin-left: 10%;
  }

  .connect2 {
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: "Playpen Sans", cursive;
    color: white;
    padding-top: 15px;
    font-size: 16px;
  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: "Playpen Sans", cursive;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;

  }

  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    height: max-content;
  }

  .nftminter2 {
    font-size: 55px;
    color: white;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    justify-content: center;
  }

  .nftminter {
    background-color: white;
    justify-content: flex-start;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    color: white;
    border: white 2px solid;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 18px;
    margin-left: 20px;
    margin-right: 20px;
    width: 45px;
    height: 45px;
    border: none;
    cursor: pointer;
    justify-content: center;
    background-color: #1e1e1e;
    border: 2px solid #ffffff;
    border-radius: 6px;
  }

  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
  }

  .btnfos-0-3 img {
    width: 32px;
    height: 32px;
  }

  .btnfos-0-3 {
    position: relative;
    font-size: 15px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 10px;
    border: none;
    justify-content: center;
    text-align: center;
    width: 90%;
    color: white;
    background-color: #1e1e1e;
    border: #ffffff 2px solid;
    margin-top: 5%;
    border-radius: 8px;
  }

  .btnfos-0-3-Dis {
    cursor: no-drop;

    font-size: 20px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 10px;
    border: none;
    justify-content: center;
    text-align: center;
    width: 250px;
    margin-bottom: 5%;
    color: rgba(255, 255, 255, 0.116);
    background-color: #1e1e1e49;
    border: #3f3f3f5e 2px solid;
    margin-top: 5%;
    border-radius: 8px;
  }

  .btnfos-0-3:hover {
    color: rgb(153, 153, 153);
    ;
    cursor: pointer;
  }

  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
  }

  .loadingContainer {
    text-align: center;
  }

  .successfully {
    text-align: center;
    color: rgb(128, 240, 54);
    font-size: 18px;
  }

  .successfully2 {
    text-align: center;
    color: rgb(124, 199, 74);
    font-size: 25px;
  }

  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .wallet2 {
    background-color: #9e4c3c00;
    border: black 2px solid;
    color: white;
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: flex;
    font-size: 20px;
    padding-left: 25px;
    padding-right: 25px;
    transition: transform .2s;
    border-radius: 50px;
    font-weight: 500;
    margin-left: 10px;
  }

  .wallet2:hover {
    transform: scale(1.1);
  }

  .wallet3 {
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    padding-left: 80px;
    padding-right: 80px;
    align-items: center;
    display: flex;
    transition: transform .5s;
    font-weight: 500;
    color: black;
    border-radius: 100px;
    z-index: 100000000000;
    margin-top: 30px;
    padding-bottom: 12px;
    padding-top: 12px;
    border: none;
    background-color: white;
    border: 1px solid black;
    box-shadow: -5px 5px rgb(129, 129, 129);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 60px;
  }

  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }



  .in2 {
    width: 100%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 35px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    text-shadow: 4px 3px 0 #383d6e3a;
  }

  .logoPic {
    width: 100px;
  }

  .logo {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .light {
    background-color: white;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
  }

  .nftPic {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    border: 3px solid transparent;
    border-image: linear-gradient(90deg, #383838, #929292);
    border-image-slice: 3;



  }

}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .networks {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    margin-top: 1%;
    margin-bottom: 50px;
    background-color: #292929;
    width: 90%;
    padding: 20px;
    border-radius: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    margin-left: auto;
    margin-right: auto;
  }

  .networks img {
    width: 160px;
    padding: 5px;
    cursor: pointer;
    filter: grayscale(1);
    transition: 0.2s ease-in-out;
  }

  .container {
    display: flex;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
  }

  .container2 {
    display: flex;
    background-color: #1e1e1e;
    width: fit-content;
    justify-content: center;
    padding: 15px;
    margin-right: auto;
    box-shadow: -5px 5px rgba(54, 54, 54, 0.267);
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
  }

  code {
    font-size: 18px;
    color: rgb(86, 192, 15);
  }

  .address-section p {
    color: #fff;
    margin: 0;
    font-size: 18px;
  }

  .copy-button {
    margin-left: 20px;
    background-color: white;
    color: black;
    border: 1px solid rgb(231, 255, 199);
    border-radius: 6px;
    font-weight: 500;
    transition: 0.2s ease-in-out;
  }

  .copy-button:hover {
    background-color: #444;
    /* Darker gray on hover */
    color: white;
  }

  .copy-button:active {
    background-color: #81b457;
    /* Darker gray on click */
    transform: translateY(2px);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
  }

  .copy-button:focus {
    outline: none;
  }

  .notification {
    position: fixed;
    bottom: 20px;
    left: 40px;
    background-color: #42b932;
    color: #ffffff;
    padding: 10px 20px;
    border-radius: 5px;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    z-index: 9999;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .wallet2Mobile {
    display: none;
  }

  .wallet3Mobile {
    display: none;
  }

  .prices p {
    color: white;
    background-color: #292929;
    border-radius: 7px;
    border: 1px solid rgba(255, 255, 255, 0.178);
    padding: 6px;
    font-size: 14px;
    margin-right: 10px;
  }

  .prices {
    width: fit-content;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .intro {
    color: rgb(0, 0, 0);
    font-weight: 500;
    font-size: 30px;
  }

  .icons {
    display: flex;
    margin-bottom: auto;
    margin-top: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .nftPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 5%;
  }

  .mintingAmount {
    font-size: 20px;
    padding: 10px;
  }

  .socialIcon img {
    width: 42px;
    height: 42px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    font-size: 18px;
    color: #ffffff;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .intro2 {
    color: #3a3a3a;
    text-align: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .pricesM div {
    color: #3a3a3a;
    margin-bottom: 5px;
  }

  .mintDiv {
    background-color: #0000004f;
    width: 60%;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 5px solid black;
    padding: 20px;
    box-shadow: 0 0 20px #99999965;
    border-radius: 20px;
    border: 2px solid black;
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
  }

  .errorMessage {
    font-size: 16px;
    color: #f74741;
    text-align: center;
  }

  .loadingText {
    font-size: 16px;
    text-align: center;
    color: rgb(255, 255, 255);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  .errorMessage2 {
    font-size: 18px;
    color: #f74741;
    text-align: center;
  }

  .loadingText2 {
    font-size: 20px;
    text-align: center;
    color: rgb(255, 255, 255);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText2:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }



  .right {
    flex-flow: row nowrap;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    width: max-content;
  }



  .socialIcon {
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
    padding-right: 15px;
  }

  .socialIcon:hover {
    transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: "Playpen Sans", cursive;
    padding-top: 5px;
    margin-left: 10%;
  }

  .connect2 {
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: "Playpen Sans", cursive;
    color: white;
    padding-top: 15px;
    font-size: 16px;
  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: "Playpen Sans", cursive;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;

  }

  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    height: max-content;
  }

  .nftminter2 {
    font-size: 55px;
    color: white;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    justify-content: center;
  }

  .nftminter {
    background-color: white;
    justify-content: flex-start;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    color: white;
    border: white 2px solid;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 18px;
    margin-left: 20px;
    margin-right: 20px;
    width: 45px;
    height: 45px;
    border: none;
    cursor: pointer;
    justify-content: center;
    background-color: #1e1e1e;
    border: 2px solid #ffffff;
    border-radius: 6px;
  }

  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
  }

  .btnfos-0-3 img {
    width: 32px;
    height: 32px;
  }

  .btnfos-0-3 {
    position: relative;
    font-size: 15px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 10px;
    border: none;
    justify-content: center;
    text-align: center;
    width: 90%;
    color: white;
    background-color: #1e1e1e;
    border: #ffffff 2px solid;
    margin-top: 5%;
    border-radius: 8px;
  }

  .btnfos-0-3-Dis {
    cursor: no-drop;

    font-size: 20px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 10px;
    border: none;
    justify-content: center;
    text-align: center;
    width: 250px;
    margin-bottom: 5%;
    color: rgba(255, 255, 255, 0.116);
    background-color: #1e1e1e49;
    border: #3f3f3f5e 2px solid;
    margin-top: 5%;
    border-radius: 8px;
  }

  .btnfos-0-3:hover {
    color: rgb(153, 153, 153);
    ;
    cursor: pointer;
  }

  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
  }

  .loadingContainer {
    text-align: center;
  }

  .successfully {
    text-align: center;
    color: rgb(128, 240, 54);
    font-size: 18px;
  }

  .successfully2 {
    text-align: center;
    color: rgb(124, 199, 74);
    font-size: 25px;
  }

  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .wallet2 {
    background-color: #9e4c3c00;
    border: black 2px solid;
    color: white;
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: flex;
    font-size: 20px;
    padding-left: 25px;
    padding-right: 25px;
    transition: transform .2s;
    border-radius: 50px;
    font-weight: 500;
    margin-left: 10px;
  }

  .wallet2:hover {
    transform: scale(1.1);
  }

  .wallet3 {
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 18px;
    padding-left: 80px;
    padding-right: 80px;
    align-items: center;
    display: flex;
    transition: transform .5s;
    font-weight: 500;
    color: black;
    border-radius: 100px;
    z-index: 100000000000;
    margin-top: 30px;
    padding-bottom: 12px;
    padding-top: 12px;
    border: none;
    background-color: white;
    border: 1px solid black;
    box-shadow: -5px 5px rgb(129, 129, 129);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 60px;
  }

  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }



  .in2 {
    width: 90%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 35px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    text-shadow: 4px 3px 0 #383d6e3a;
  }

  .logoPic {
    width: 100px;
  }

  .logo {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .light {
    background-color: white;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
  }

  .nftPic {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    border: 3px solid transparent;
    border-image: linear-gradient(90deg, #383838, #929292);
    border-image-slice: 3;



  }

  .intro2Main {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: #3b3b3b;
    width: 90%;
    padding: 20px;
    margin-bottom: 70px;
    margin-top: 70px;
    border-radius: 20px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .networks {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    margin-top: 1%;
    background-color: #292929;
    width: 90%;
    padding: 20px;
    border-radius: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  }

  .networks img {
    width: 140px;
    padding: 5px;
    cursor: pointer;
    filter: grayscale(1);
    transition: 0.2s ease-in-out;
  }

  .container {
    display: flex;
    width: 100%;
    margin-right: auto;
    margin: 0;
    padding: 0;
  }

  .container2 {
    display: flex;
    background-color: #1e1e1e;
    width: fit-content;
    justify-content: center;
    padding: 15px;
    margin-right: auto;
    box-shadow: -5px 5px rgba(54, 54, 54, 0.267);
  }

  code {
    font-size: 18px;
    color: rgb(86, 192, 15);
  }

  .address-section p {
    color: #fff;
    margin: 0;
    font-size: 18px;
  }

  .copy-button {
    margin-left: 20px;
    background-color: white;
    color: black;
    border: 1px solid rgb(231, 255, 199);
    border-radius: 6px;
    font-weight: 500;
    transition: 0.2s ease-in-out;
  }

  .copy-button:hover {
    background-color: #444;
    /* Darker gray on hover */
    color: white;
  }

  .copy-button:active {
    background-color: #81b457;
    /* Darker gray on click */
    transform: translateY(2px);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
  }

  .copy-button:focus {
    outline: none;
  }

  .notification {
    position: fixed;
    bottom: 20px;
    left: 40px;
    background-color: #42b932;
    color: #ffffff;
    padding: 10px 20px;
    border-radius: 5px;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    z-index: 9999;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .wallet2Mobile {
    display: none;
  }

  .wallet3Mobile {
    display: none;
  }

  .prices p {
    color: white;
    background-color: #292929;
    border-radius: 7px;
    border: 1px solid rgba(255, 255, 255, 0.178);
    padding: 6px;
    font-size: 13px;
    margin-right: 10px;
  }

  .prices {
    width: fit-content;
    display: flex;
  }

  .intro2Main {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: #3b3b3b;
    width: 50%;
    padding: 30px;
    margin-bottom: 70px;
    margin-top: 70px;
    border-radius: 20px;
  }

  .icons {
    display: flex;
  }

  .intro {
    color: rgb(0, 0, 0);
    font-weight: 500;
    font-size: 30px;
    margin-bottom: 10px;
  }

  .mintingAmount {
    font-size: 20px;
    padding: 10px;
  }

  .socialIcon img {
    width: 42px;
    height: 42px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    color: #ffffff;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .storyPicDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .intro2 {
    color: #3a3a3a;
    text-align: left;
    width: 90%;
    margin-top: 10px;

  }

  .pricesM div {
    color: #3a3a3a;
    margin-bottom: 5px;
  }

  .mintDiv {
    background-color: #0000004f;
    width: 60%;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 5px solid black;
    padding: 20px;
    box-shadow: 0 0 20px #99999965;
    border-radius: 20px;
    border: 2px solid black;
  }

  .errorMessage {
    font-size: 16px;
    color: #f74741;
    text-align: center;
  }

  .loadingText {
    font-size: 16px;
    text-align: center;
    color: rgb(255, 255, 255);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */

  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  .errorMessage2 {
    font-size: 18px;
    color: #f74741;
    text-align: left;
  }

  .loadingText2 {
    font-size: 23px;
    text-align: left;
    color: rgb(255, 255, 255);
  }

  .loadTextSub2 {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */

  .loadingText2:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }



  .right {
    flex-flow: row nowrap;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    width: max-content;
  }


  .socialIcon {
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
    padding-right: 15px;
  }

  .socialIcon:hover {
    transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: "Playpen Sans", cursive;
    padding-top: 5px;
    margin-left: 10%;
  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: "Playpen Sans", cursive;
    color: white;
    padding-top: 15px;
    font-size: 16px;
  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: "Playpen Sans", cursive;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;

  }

  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    height: max-content;
  }

  .nftminter2 {
    font-size: 55px;
    color: white;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    justify-content: center;
  }

  .nftminter {
    background-color: white;
    justify-content: flex-start;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    color: white;
    border: white 2px solid;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 18px;
    margin-left: 20px;
    margin-right: 20px;
    width: 45px;
    height: 45px;
    border: none;
    cursor: pointer;
    justify-content: center;
    background-color: #1e1e1e;
    border: 2px solid #ffffff;
    border-radius: 6px;
  }

  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
  }

  .btnfos-0-3 img {
    width: 32px;
    height: 32px;
  }

  .btnfos-0-3 {
    position: relative;
    font-size: 15px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 5px;
    border: none;
    justify-content: center;
    text-align: center;
    width: 90%;
    color: white;
    background-color: #1e1e1e;
    border: #ffffff 2px solid;
    margin-top: 5%;
    border-radius: 6px;
  }

  .btnfos-0-3-Dis {
    cursor: no-drop;

    font-size: 23px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 5px;
    border: none;
    justify-content: center;
    text-align: center;
    width: 250px;
    margin-bottom: 5%;
    color: rgba(255, 255, 255, 0.116);
    background-color: #1e1e1e49;
    border: #3f3f3f5e 2px solid;
    margin-top: 5%;
    border-radius: 6px;
  }


  .btnfos-0-3:hover {
    color: rgb(153, 153, 153);
    ;
    cursor: pointer;
  }

  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
  }

  .loadingContainer {
    text-align: center;
  }

  .successfully {
    text-align: center;
    color: rgb(128, 240, 54);
    font-size: 18px;
  }

  .successfully2 {
    text-align: left;
    color: rgb(124, 199, 74);
    font-size: 25px;
  }

  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }

  .introduction {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: 2%;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    margin-top: 13vh;

  }

  .wallet2 {
    background-color: #9e4c3c00;
    border: black 2px solid;
    color: white;
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    padding-left: 25px;
    padding-right: 25px;
    transition: transform .2s;
    border-radius: 50px;
    font-weight: 500;
    margin-left: 10px;
  }

  .wallet2:hover {
    transform: scale(1.1);
  }

  .wallet3 {
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 18px;
    padding-left: 80px;
    padding-right: 80px;
    align-items: center;
    display: flex;
    transition: transform .5s;
    font-weight: 500;
    color: black;
    border-radius: 100px;
    z-index: 100000000000;
    margin-top: 30px;
    padding-bottom: 12px;
    padding-top: 12px;
    border: none;
    background-color: white;
    border: 1px solid black;
    box-shadow: -5px 5px rgb(129, 129, 129);
  }

  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }



  .in2 {
    width: 100%;
    text-align: left;
    margin-top: auto;
    margin-bottom: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    text-shadow: 4px 3px 0 #383d6e3a;
  }

  .logoPic {
    width: 120px;
  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
  }

  .light {
    background-color: white;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
  }

  .nftPic {
    width: 310px;
    display: flex;
    border: 3px solid transparent;
    border-image: linear-gradient(90deg, #383838, #929292);
    border-image-slice: 3;



  }

}

@media screen and (min-width: 1200px) and (max-width: 1919px) {

  .networks {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    margin-top: 1%;
    background-color: #292929;
    width: 90%;
    padding: 20px;
    border-radius: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    margin-left: auto;
    margin-right: auto;
  }

  .networks img {
    width: 160px;
    padding: 5px;
    cursor: pointer;
    filter: grayscale(1);
    transition: 0.2s ease-in-out;
  }

  .container {
    display: flex;
    width: 100%;
    margin-right: auto;
    margin: 0;
    padding: 0;
  }

  .container2 {
    display: flex;
    background-color: #1e1e1e;
    width: fit-content;
    justify-content: center;
    padding: 15px;
    margin-right: auto;
    box-shadow: -5px 5px rgba(54, 54, 54, 0.267);
  }

  code {
    font-size: 18px;
    color: rgb(86, 192, 15);
  }

  .address-section p {
    color: #fff;
    margin: 0;
    font-size: 18px;
  }

  .copy-button {
    margin-left: 20px;
    background-color: white;
    color: black;
    border: 1px solid rgb(231, 255, 199);
    border-radius: 6px;
    font-weight: 500;
    transition: 0.2s ease-in-out;
  }

  .copy-button:hover {
    background-color: #444;
    /* Darker gray on hover */
    color: white;
  }

  .copy-button:active {
    background-color: #81b457;
    /* Darker gray on click */
    transform: translateY(2px);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
  }

  .copy-button:focus {
    outline: none;
  }

  .notification {
    position: fixed;
    bottom: 30px;
    left: 40px;
    background-color: #42b932;
    color: #ffffff;
    padding: 10px 20px;
    border-radius: 5px;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    z-index: 9999;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .wallet2Mobile {
    display: none;
  }

  .wallet3Mobile {
    display: none;
  }

  .prices p {
    color: white;
    background-color: #292929;
    border-radius: 7px;
    border: 1px solid rgba(255, 255, 255, 0.178);
    padding: 6px;
    font-size: 14px;
    margin-right: 10px;
  }

  .prices {
    width: fit-content;
    display: flex;
  }


  .intro2Main {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: #3b3b3b;
    width: 40%;
    padding: 30px;
    margin-bottom: 5%;
    margin-top: 4%;
    border-radius: 20px;
  }

  .icons {
    display: flex;
  }


  .intro {
    color: rgb(0, 0, 0);
    font-size: 35px;
    font-family: "Playpen Sans", cursive;
  }

  /*.nftPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: flex-end;
    display: flex;
   }*/

  .mintingAmount {
    font-size: 20px;
    padding: 10px;
  }

  .socialIcon img {
    width: 42px;
    height: 42px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    font-size: 18px;
    color: #ffffff;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .storyPicDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .intro2 {
    color: #3a3a3a;
    font-size: 16px;
    text-align: left;
    width: 90%;
  }

  .pricesM div {
    color: #3a3a3a;
    margin-bottom: 5px;
  }

  .mintDiv {
    background-color: #0000004f;
    width: 55%;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 5px solid black;
    padding: 20px;
    box-shadow: 0 0 20px #99999965;
    border-radius: 20px;
    border: 2px solid black;
  }

  .errorMessage {
    font-size: 16px;
    color: #f74741;
    text-align: center;
  }

  .loadingText {
    font-size: 16px;
    text-align: center;
    color: rgb(255, 255, 255);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 13px;
    padding-top: 5px;
  }

  .errorMessage2 {
    font-size: 18px;
    color: #f74741;
    text-align: lefts;
  }

  .loadingText2 {
    text-align: left;
    color: rgb(255, 255, 255);
  }

  .loadTextSub2 {
    text-align: left;
    color: rgb(255, 255, 255);
    font-size: 13px;
    padding-top: 5px;
  }

  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  .loadingText2:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }



  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    width: max-content;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .socialIcon {
    padding-right: 15px;
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .socialIcon:hover {
    transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: "Playpen Sans", cursive;
    padding-top: 5px;
    margin-left: 10%;

  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: "Playpen Sans", cursive;
    color: white;
    padding-top: 15px;
    font-size: 16px;
  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: "Playpen Sans", cursive;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;
  }

  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    height: max-content;
  }

  .nftminter2 {
    font-size: 70px;
    color: white;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    justify-content: center;
  }

  .nftminter {
    background-color: white;
    justify-content: flex-start;
  }

  .mintbuttondiv {
    text-align: center;
    flex-flow: column nowrap;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    color: white;
    border: white 2px solid;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    width: 50px;
    height: 50px;
    border: none;
    cursor: pointer;
    justify-content: center;
    background-color: #1e1e1e;
    border: 2px solid #ffffff;
    border-radius: 6px;
  }

  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
  }

  .btnfos-0-3 img {
    width: 35px;
    height: 35px;
  }

  .btnfos-0-3 {
    position: relative;
    font-size: 16px;
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 10px;
    border: none;
    justify-content: center;
    text-align: center;
    color: white;
    background-color: #1e1e1e;
    border: #ffffff 2px solid;
    margin-top: 5%;
    border-radius: 6px;
    position: relative;
  }

  .btnfos-0-3-Dis {
    cursor: no-drop;

    font-size: 23px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 5px;
    border: none;
    justify-content: center;
    text-align: center;
    width: 250px;
    margin-bottom: 5%;
    color: rgba(255, 255, 255, 0.116);
    background-color: #1e1e1e49;
    border: #3f3f3f5e 2px solid;
    margin-top: 5%;
    border-radius: 6px;
    cursor: no-drop;
  }

  .btnfos-0-3:hover {
    color: rgb(153, 153, 153);
    ;
    cursor: pointer;
  }

  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  }

  .loadingContainer {
    text-align: center;
  }

  .successfully {
    text-align: center;
    color: rgb(128, 240, 54);
    font-size: 18px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
  }

  .successfully2 {
    text-align: left;
    color: rgb(128, 240, 54);
    font-size: 24px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .introduction {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    margin-top: 10vh;
  }

  .wallet2 {
    background-color: #9e4c3c00;
    border: black 2px solid;
    color: white;
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    padding-left: 25px;
    padding-right: 25px;
    transition: transform .2s;
    border-radius: 50px;
    font-weight: 500;
    margin-left: 5px;
  }

  .wallet2:hover {
    transform: scale(1.1);
  }

  .wallet3 {
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 18px;
    padding-left: 80px;
    padding-right: 80px;
    align-items: center;
    display: flex;
    transition: transform .5s;
    font-weight: 500;
    color: black;
    border-radius: 100px;
    z-index: 100000000000;
    margin-top: 30px;
    padding-bottom: 12px;
    padding-top: 12px;
    border: none;
    background-color: white;
    border: 1px solid black;
    box-shadow: -5px 5px rgb(129, 129, 129);
  }

  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }



  .in2 {
    width: 90%;
    text-align: left;
    margin-top: auto;
    margin-bottom: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 35px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    text-shadow: 4px 3px 0 #383d6e3a;
  }

  .logoPic {
    width: 120px;
  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
  }

  .light {
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
    background-color: white;
  }

  .nftPic {
    width: 400px;
    border: 3px solid transparent;
    border-image: linear-gradient(90deg, #383838, #929292);
    border-image-slice: 3;


  }

  @keyframes neon {
    0% {
      border-image-source: linear-gradient(90deg, #4CE2D1, black, #FEE100, #f276d0);
    }

    25% {
      border-image-source: linear-gradient(90deg, #FEE100, #4CE2D1, black, #f276d0);
    }

    50% {
      border-image-source: linear-gradient(90deg, black, #FEE100, #4CE2D1, #f276d0);
    }

    75% {
      border-image-source: linear-gradient(90deg, #f276d0, black, #FEE100, #4CE2D1);
    }

    100% {
      border-image-source: linear-gradient(90deg, #4CE2D1, black, #FEE100, #f276d0);
    }
  }
}

@media screen and (min-width: 1920px) {

  .networks {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    margin-top: 1%;
    background-color: #292929;
    width: 90%;
    padding: 30px;
    border-radius: 15px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  }

  .networks img {
    width: 220px;
    padding: 7px;
    cursor: pointer;
    filter: grayscale(1);
    transition: 0.2s ease-in-out;
  }

  .container {
    display: flex;
    width: 100%;
    margin-right: auto;
    margin: 0;
    padding: 0;
  }

  .container2 {
    display: flex;
    background-color: #1e1e1e;
    width: fit-content;
    justify-content: center;
    padding: 24px;
    margin-right: auto;
    box-shadow: -10px 10px rgba(54, 54, 54, 0.267);
  }

  code {
    font-size: 24px;
    color: rgb(86, 192, 15);
  }

  .address-section p {
    color: #fff;
    margin: 0;
    font-size: 24px;
  }

  .copy-button {
    margin-left: 30px;
    background-color: white;
    color: black;
    border: 2px solid rgb(231, 255, 199);
    border-radius: 8px;
    font-weight: 500;
    transition: 0.2s ease-in-out;
    font-size: 22px;
  }

  .copy-button:hover {
    background-color: #444;
    /* Darker gray on hover */
    color: white;
  }

  .copy-button:active {
    background-color: #81b457;
    /* Darker gray on click */
    transform: translateY(2px);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
  }

  .copy-button:focus {
    outline: none;
  }

  .notification {
    position: fixed;
    bottom: 50px;
    left: 60px;
    background-color: #42b932;
    color: #ffffff;
    padding: 15px 24px;
    border-radius: 7px;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    z-index: 9999;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    font-size: 22px;
  }

  .wallet2Mobile {
    display: none;
  }

  .wallet3Mobile {
    display: none;
  }

  .prices p {
    color: white;
    background-color: #292929;
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.178);
    padding: 11px;
    font-size: 19.5px;
    margin-right: 15px;
  }

  .prices {
    width: fit-content;
    display: flex;
  }

  .intro2Main {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: #3b3b3b;
    width: 40%;
    padding: 40px;
    margin-bottom: 5%;
    margin-top: 4%;
    border-radius: 20px;
  }

  .intro {
    color: rgb(0, 0, 0);
    font-weight: 500;
    font-size: 50px;
    margin-bottom: 5px;
    font-family: "Playpen Sans", cursive;
  }

  .icons {
    display: flex;
  }

  .socialIcon img {
    width: 57px;
    height: 57px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    font-size: 25px;
    color: #ffffff;
  }

  .socialIcon {
    padding-right: 25px;
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .socialIcon:hover {
    transform: scale(1.1);
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .intro2 {
    color: #3a3a3a;
    font-size: 23px;
    text-align: left;
    width: 90%;
  }

  .pricesM div {
    color: #3a3a3a;
    font-size: 23px;
  }

  .pricesM div {
    color: #3a3a3a;
    margin-bottom: 8px;
  }

  .intro2 p {
    margin-bottom: 22px;
    margin-top: 22px;
  }

  .mintDiv {
    background-color: #0000004f;
    width: 60%;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 5px solid black;
    padding: 30px;
    box-shadow: 0 0 20px #99999965;
    border-radius: 30px;
    border: 4px solid black;
  }

  .errorMessage {
    font-size: 22px;
    color: #f74741;
    text-align: center;
  }

  .loadingText {
    font-size: 22px;
    text-align: center;
    color: rgb(255, 255, 255);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 13px;
    padding-top: 5px;
  }

  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  .errorMessage2 {
    font-size: 30px;
    color: #f74741;
    text-align: left;
  }

  .loadingText2 {
    font-size: 30px;
    text-align: left;
    color: rgb(255, 255, 255);
  }

  .loadTextSub {
    text-align: left;
    color: rgb(255, 255, 255);
    font-size: 13px;
    padding-top: 5px;
  }

  .loadingText2:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }



  .right {
    flex-flow: row nowrap;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    width: max-content;
    margin-top: auto;
    margin-bottom: auto;
  }


  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: "Playpen Sans", cursive;
    padding-top: 5px;
    margin-left: 10%;
  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;

  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: "Playpen Sans", cursive;
    color: white;
    padding-top: 15px;
    font-size: 16px;
  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: "Playpen Sans", cursive;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;
  }

  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    height: max-content;
  }

  .nftminter2 {
    font-size: 110px;
    color: white;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    justify-content: center;
  }

  .nftminter {
    background-color: white;
    justify-content: flex-start;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    color: white;
    background-color: rgba(255, 255, 255, 0);
    border: white 2px solid;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 30px;
    margin-left: 30px;
    margin-right: 30px;
    height: 75px;
    width: 75px;
    justify-content: center;
    background-color: #1e1e1e;
    border: 4px solid #ffffff;
    border-radius: 12px;
  }

  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
  }

  .btnfos-0-3 img {
    width: 50px;
    height: 50px;
  }

  .btnfos-0-3 {
    position: relative;
    font-size: 24px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 10px;
    border: none;
    justify-content: center;
    text-align: center;
    width: 90%;
    color: white;
    background-color: #1e1e1e;
    border: #ffffff 3px solid;
    margin-top: 5%;
    border-radius: 9px;
  }

  .btnfos-0-3-Dis {
    cursor: no-drop;

    font-size: 32px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 10px;
    border: none;
    justify-content: center;
    text-align: center;
    width: 60%;
    margin-bottom: 5%;
    color: rgba(255, 255, 255, 0.116);
    background-color: #1e1e1e49;
    border: #3f3f3f5e 3px solid;
    margin-top: 5%;
    border-radius: 9px;
  }


  .btnfos-0-3:hover {
    color: rgb(153, 153, 153);
    cursor: pointer;
  }

  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
  }

  .loadingContainer {
    text-align: center;
  }

  .successfully {
    text-align: center;
    color: rgb(128, 240, 54);
    font-size: 24px;
  }

  .successfully2 {
    text-align: left;
    color: rgb(124, 199, 74);
    font-size: 25px;
  }

  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 40px;
    margin-left: auto;
    margin-right: auto;
  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .introduction {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: 13vh;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }

  .wallet2 {
    background-color: #9e4c3c00;
    border: black 4px solid;
    color: white;
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    padding-left: 35px;
    padding-right: 35px;

    transition: transform .2s;
    border-radius: 50px;
    font-weight: 500;
    margin-left: 10px;
  }

  .wallet2:hover {
    transform: scale(1.1);
  }

  .wallet3 {
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 26px;
    padding-left: 110px;
    padding-right: 110px;
    align-items: center;
    display: flex;
    transition: transform .5s;
    font-weight: 500;
    color: rgb(0, 0, 0);
    background: #c0354100;
    border-radius: 10px;
    border: none;
    margin-top: 40px;
    border: none;
    background-color: white;
    border: 2px solid black;
    box-shadow: -7px 7px rgb(129, 129, 129);
    border-image-slice: 1;
    padding-bottom: 18px;
    padding-top: 18px;
    border-radius: 200px;
  }

  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }



  .in2 {
    width: 90%;
    text-align: left;
    margin-top: auto;
    margin-bottom: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    text-shadow: 4px 3px 0 #383d6e3a;
  }

  .logoPic {
    width: 170px;
  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
  }

  .light2 {
    height: 200vh;
  }

  .light {
    background-color: white;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
  }

  .nftPic {
    width: 600px;
    border: 6px solid transparent;
    border-image: linear-gradient(90deg, #383838, #929292);
    border-image-slice: 6;



  }

}

@media screen and (min-width: 1921px) and (max-width: 3839px) {

  .networks {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    margin-top: 1%;
    background-color: #292929;
    width: 90%;
    padding: 40px;
    border-radius: 20px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  }

  .networks img {
    width: 300px;
    padding: 10px;
    cursor: pointer;
    filter: grayscale(1);
    transition: 0.2s ease-in-out;
  }

  .container {
    display: flex;
    width: 100%;
    margin-right: auto;
    margin: 0;
    padding: 0;
  }

  .container2 {
    display: flex;
    background-color: #1e1e1e;
    width: fit-content;
    justify-content: center;
    padding: 32px;
    margin-right: auto;
    box-shadow: -15px 15px rgba(54, 54, 54, 0.267);
    margin-top: 20px;
  }

  code {
    font-size: 34px;
    color: rgb(86, 192, 15);
  }

  .address-section p {
    color: #fff;
    margin: 0;
    font-size: 34px;
  }

  .copy-button {
    margin-left: 40px;
    background-color: white;
    color: black;
    border: 4px solid rgb(231, 255, 199);
    border-radius: 10px;
    font-weight: 500;
    transition: 0.2s ease-in-out;
    font-size: 32px;
    padding: 10px;
  }

  .copy-button:hover {
    background-color: #444;
    /* Darker gray on hover */
    color: white;
  }

  .copy-button:active {
    background-color: #81b457;
    /* Darker gray on click */
    transform: translateY(2px);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
  }

  .copy-button:focus {
    outline: none;
  }

  .notification {
    position: fixed;
    bottom: 90px;
    left: 70px;
    background-color: #42b932;
    color: #ffffff;
    padding: 20px 30px;
    border-radius: 10px;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    z-index: 9999;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    font-size: 32px;
  }

  .wallet2Mobile {
    display: none;
  }

  .wallet3Mobile {
    display: none;
  }

  .prices p {
    color: white;
    background-color: #292929;
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.178);
    padding: 13px;
    font-size: 26px;
    margin-right: 20px;
  }

  .prices {
    width: fit-content;
    display: flex;
  }

  .intro2Main {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: #3b3b3b;
    width: 40%;
    padding: 60px;
    margin-bottom: 5%;
    margin-top: 4%;
    border-radius: 40px;
  }

  .intro {
    color: rgb(0, 0, 0);
    font-weight: 500;
    font-size: 72px;
    margin-bottom: 5px;
    font-family: "Playpen Sans", cursive;
  }

  .icons {
    display: flex;
  }

  .socialIcon img {
    width: 72px;
    height: 72px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    font-size: 34px;
    color: #ffffff;
  }

  .socialIcon {
    padding-right: 28px;
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .socialIcon:hover {
    transform: scale(1.1);
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .intro2 {
    color: #3a3a3a;
    font-size: 30px;
    text-align: left;
    width: 90%;
  }

  .pricesM div {
    color: #3a3a3a;
    font-size: 30px;
  }

  .pricesM div {
    color: #3a3a3a;
    margin-bottom: 12px;
  }

  .intro2 p {
    margin-bottom: 26px;
    margin-top: 26px;
  }

  .mintDiv {
    background-color: #0000004f;
    width: 50%;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 5px solid black;
    padding: 40px;
    box-shadow: 0 0 20px #99999965;
    border-radius: 30px;
    border: 4px solid black;
  }

  .errorMessage {
    font-size: 32px;
    color: #f74741;
    text-align: center;
  }

  .loadingText {
    font-size: 32px;
    text-align: center;
    color: rgb(255, 255, 255);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 13px;
    padding-top: 5px;
  }

  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  .errorMessage2 {
    font-size: 30px;
    color: #f74741;
    text-align: left;
  }

  .loadingText2 {
    font-size: 30px;
    text-align: left;
    color: rgb(255, 255, 255);
  }

  .loadTextSub {
    text-align: left;
    color: rgb(255, 255, 255);
    font-size: 13px;
    padding-top: 5px;
  }

  .loadingText2:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }



  .right {
    flex-flow: row nowrap;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    width: max-content;
    margin-top: auto;
    margin-bottom: auto;
  }


  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: "Playpen Sans", cursive;
    padding-top: 5px;
    margin-left: 10%;
  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: "Playpen Sans", cursive;
    color: white;
    padding-top: 15px;
    font-size: 16px;
  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: "Playpen Sans", cursive;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;
  }

  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    height: max-content;
  }

  .nftminter2 {
    font-size: 135px;
    color: white;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    justify-content: center;
  }

  .nftminter {
    background-color: white;
    justify-content: flex-start;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    color: white;
    background-color: rgba(255, 255, 255, 0);
    border: white 2px solid;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 35px;
    margin-left: 40px;
    margin-right: 40px;
    height: 100px;
    width: 100px;
    justify-content: center;
    background-color: #1e1e1e;
    border: 4px solid #ffffff;
    border-radius: 15px;
  }

  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
  }


  .btnfos-0-3 img {
    width: 70px;
    height: 70px;
  }

  .btnfos-0-3 {
    position: relative;
    font-size: 34px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 15px;
    border: none;
    justify-content: center;
    text-align: center;
    width: 90%;
    color: white;
    background-color: #1e1e1e;
    border: #ffffff 4px solid;
    margin-top: 5%;
    border-radius: 12px;
  }

  .btnfos-0-3-Dis {
    cursor: no-drop;

    font-size: 44px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 15px;
    border: none;
    justify-content: center;
    text-align: center;
    width: 70%;
    margin-bottom: 5%;
    color: rgba(255, 255, 255, 0.116);
    background-color: #1e1e1e49;
    border: #3f3f3f5e 4px solid;
    margin-top: 5%;
    border-radius: 12px;
  }


  .btnfos-0-3:hover {
    color: rgb(153, 153, 153);
    ;
    cursor: pointer;
  }

  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
  }

  .loadingContainer {
    text-align: center;
  }

  .successfully {
    text-align: center;
    color: rgb(128, 240, 54);
    font-size: 35px;
  }

  .successfully2 {
    text-align: left;
    color: rgb(124, 199, 74);
    font-size: 25px;
  }

  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 50px;
    margin-left: auto;
    margin-right: auto;
  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .introduction {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: 13vh;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }

  .wallet2 {
    background-color: #9e4c3c00;
    border: black 4px solid;
    color: white;
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    padding-left: 35px;
    padding-right: 35px;

    transition: transform .2s;
    border-radius: 50px;
    font-weight: 500;
    margin-left: 10px;
  }

  .wallet2:hover {
    transform: scale(1.1);
  }

  .wallet3 {
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 34px;
    padding-left: 160px;
    padding-right: 160px;
    align-items: center;
    display: flex;
    transition: transform .5s;
    font-weight: 500;
    color: rgb(0, 0, 0);
    background: #c0354100;
    border-radius: 10px;
    border: none;
    margin-top: 60px;
    border: none;
    background-color: white;
    border: 3px solid black;
    box-shadow: -10px 10px rgb(129, 129, 129);
    border-image-slice: 1;
    padding-bottom: 22px;
    padding-top: 22px;
    border-radius: 200px;
  }

  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }



  .in2 {
    width: 90%;
    text-align: left;
    margin-top: auto;
    margin-bottom: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 65px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    text-shadow: 4px 3px 0 #383d6e3a;
  }

  .logoPic {
    width: 215px;
  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
  }

  .light2 {
    height: 200vh;
  }

  .light {
    background-color: white;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
  }

  .nftPic {
    width: 730px;
    border: 6px solid transparent;
    border-image: linear-gradient(90deg, #383838, #929292);
    border-image-slice: 6;



  }

}

@media screen and (min-width: 3840px) {

  .networks {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    margin-top: 1%;
    background-color: #292929;
    width: 90%;
    padding: 40px;
    border-radius: 40px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  }

  .networks img {
    width: 450px;
    padding: 20px;
    cursor: pointer;
    filter: grayscale(1);
    transition: 0.2s ease-in-out;
  }

  .container {
    display: flex;
    width: 100%;
    margin-right: auto;
    margin: 0;
    padding: 0;
  }

  .container2 {
    display: flex;
    background-color: #1e1e1e;
    width: fit-content;
    justify-content: center;
    padding: 42px;
    margin-right: auto;
    box-shadow: -20px 20px rgba(54, 54, 54, 0.267);
    margin-top: 40px;
  }

  code {
    font-size: 46px;
    color: rgb(86, 192, 15);
  }

  .address-section p {
    color: #fff;
    margin: 0;
    font-size: 46px;
  }

  .copy-button {
    margin-left: 40px;
    background-color: white;
    color: black;
    border: 6px solid rgb(231, 255, 199);
    border-radius: 15px;
    font-weight: 500;
    transition: 0.2s ease-in-out;
    font-size: 44px;
    padding: 20px;
  }

  .copy-button:hover {
    background-color: #444;
    /* Darker gray on hover */
    color: white;
  }

  .copy-button:active {
    background-color: #81b457;
    /* Darker gray on click */
    transform: translateY(2px);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
  }

  .copy-button:focus {
    outline: none;
  }

  .notification {
    position: fixed;
    bottom: 90px;
    left: 100px;
    background-color: #42b932;
    color: #ffffff;
    padding: 25px 35px;
    border-radius: 15px;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    z-index: 9999;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    font-size: 42px;
  }

  .wallet2Mobile {
    display: none;
  }

  .wallet3Mobile {
    display: none;
  }

  .prices p {
    color: white;
    background-color: #292929;
    border-radius: 18px;
    border: 1px solid rgba(255, 255, 255, 0.178);
    padding: 17px;
    font-size: 41px;
    margin-right: 25px;
  }

  .prices {
    width: fit-content;
    display: flex;
  }

  .intro2Main {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: #3b3b3b;
    width: 40%;
    padding: 70px;
    margin-bottom: 5%;
    margin-top: 4%;
    border-radius: 50px;
  }

  .intro {
    color: rgb(0, 0, 0);
    font-weight: 500;
    font-size: 100px;
    margin-bottom: 20px;
    font-family: "Playpen Sans", cursive;
  }

  .icons {
    display: flex;
  }

  .socialIcon img {
    width: 95px;
    height: 95px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    font-size: 48px;
    color: #ffffff;
  }

  .socialIcon {
    padding-right: 35px;
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .socialIcon:hover {
    transform: scale(1.1);
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .intro2 {
    color: #3a3a3a;
    font-size: 48px;
    text-align: left;
    width: 90%;
  }

  .pricesM div {
    color: #3a3a3a;
    font-size: 48px;
  }

  .pricesM div {
    color: #3a3a3a;
    margin-bottom: 16px;
  }

  .intro2 p {
    margin-bottom: 42px;
    margin-top: 42px;
  }

  .mintDiv {
    background-color: #0000004f;
    width: 50%;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 5px solid black;
    padding: 50px;
    box-shadow: 0 0 20px #99999965;
    border-radius: 60px;
    border: 6px solid black;
  }

  .errorMessage {
    font-size: 42px;
    color: #f74741;
    text-align: center;
  }

  .loadingText {
    font-size: 45px;
    text-align: center;
    color: rgb(255, 255, 255);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 13px;
    padding-top: 5px;
  }

  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  .errorMessage2 {
    font-size: 30px;
    color: #f74741;
    text-align: left;
  }

  .loadingText2 {
    font-size: 30px;
    text-align: left;
    color: rgb(255, 255, 255);
  }

  .loadTextSub {
    text-align: left;
    color: rgb(255, 255, 255);
    font-size: 13px;
    padding-top: 5px;
  }

  .loadingText2:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }



  .right {
    flex-flow: row nowrap;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    width: max-content;
    margin-top: auto;
    margin-bottom: auto;
  }


  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: "Playpen Sans", cursive;
    padding-top: 5px;
    margin-left: 10%;
  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: "Playpen Sans", cursive;
    color: white;
    padding-top: 15px;
    font-size: 16px;
  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: "Playpen Sans", cursive;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;
  }

  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    height: max-content;
  }

  .nftminter2 {
    font-size: 180px;
    color: white;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    justify-content: center;
  }

  .nftminter {
    background-color: white;
    justify-content: flex-start;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    color: white;
    background-color: rgba(255, 255, 255, 0);
    border: white 2px solid;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 55px;
    margin-left: 50px;
    margin-right: 50px;
    height: 135px;
    width: 135px;
    justify-content: center;
    background-color: #1e1e1e;
    border: 6px solid #ffffff;
    border-radius: 20px;
  }

  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
  }

  .btnfos-0-3 img {
    width: 95px;
    height: 95px;
  }

  .btnfos-0-3 {
    position: relative;
    font-size: 48px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 22px;
    border: none;
    justify-content: center;
    text-align: center;
    width: 90%;
    color: white;
    background-color: #1e1e1e;
    border: #ffffff 6px solid;
    margin-top: 5%;
    border-radius: 18px;
  }

  .btnfos-0-3-Dis {
    cursor: no-drop;

    font-size: 60px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 22px;
    border: none;
    justify-content: center;
    text-align: center;
    width: 70%;
    margin-bottom: 5%;
    color: rgba(255, 255, 255, 0.116);
    background-color: #1e1e1e49;
    border: #3f3f3f5e 6px solid;
    margin-top: 5%;
    border-radius: 18px;
  }


  .btnfos-0-3:hover {
    color: rgb(153, 153, 153);
    ;
    cursor: pointer;
  }

  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
  }

  .loadingContainer {
    text-align: center;
  }

  .successfully {
    text-align: center;
    color: rgb(128, 240, 54);
    font-size: 45px;
  }

  .successfully2 {
    text-align: left;
    color: rgb(124, 199, 74);
    font-size: 25px;
  }

  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 80px;
    margin-left: auto;
    margin-right: auto;
  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .introduction {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: 13vh;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }

  .wallet2 {
    background-color: #9e4c3c00;
    border: black 4px solid;
    color: white;
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    padding-left: 35px;
    padding-right: 35px;

    transition: transform .2s;
    border-radius: 50px;
    font-weight: 500;
    margin-left: 10px;
  }

  .wallet2:hover {
    transform: scale(1.1);
  }

  .wallet3 {
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 52px;
    padding-left: 230px;
    padding-right: 230px;
    align-items: center;
    display: flex;
    transition: transform .5s;
    font-weight: 500;
    color: rgb(0, 0, 0);
    background: #c0354100;
    border-radius: 10px;
    border: none;
    margin-top: 90px;
    border: none;
    background-color: white;
    border: 5px solid black;
    box-shadow: -10px 10px rgb(129, 129, 129);
    border-image-slice: 1;
    padding-bottom: 38px;
    padding-top: 38px;
    border-radius: 200px;
  }

  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }



  .in2 {
    width: 90%;
    text-align: left;
    margin-top: auto;
    margin-bottom: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 95px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    text-shadow: 4px 3px 0 #383d6e3a;
  }

  .logoPic {
    width: 330px;
  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
  }

  .light2 {
    height: 200vh;
  }

  .light {
    background-color: white;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
  }

  .nftPic {
    width: 1250px;
    border: 12px solid transparent;
    border-image: linear-gradient(90deg, #383838, #929292);
    border-image-slice: 15;

    box-shadow: 0 0 50px rgba(140, 71, 252, 0.7);

  }

}

@keyframes neon {
  0% {
    border-image-source: linear-gradient(90deg, #4CE2D1, black, #FEE100, #f276d0);
  }

  25% {
    border-image-source: linear-gradient(90deg, #FEE100, #4CE2D1, black, #f276d0);
  }

  50% {
    border-image-source: linear-gradient(90deg, black, #FEE100, #4CE2D1, #f276d0);
  }

  75% {
    border-image-source: linear-gradient(90deg, #f276d0, black, #FEE100, #4CE2D1);
  }

  100% {
    border-image-source: linear-gradient(90deg, #4CE2D1, black, #FEE100, #f276d0);
  }
}

.btnfos-0-3 .initialText {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
  cursor: no-drop;
}

.btnfos-0-3:hover .initialText {
  opacity: 0;
  visibility: hidden;
  cursor: no-drop;

}

.btnfos-0-3 .hoverText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
  width: 90%;
  cursor: no-drop;
}

.btnfos-0-3:hover .hoverText {
  opacity: 1;
  visibility: visible;
  cursor: no-drop;
}